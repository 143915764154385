import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { OrganisationService } from '../../modules/organisation/organisation.service';
import { Router } from '@angular/router';
import { MenuRoute } from '../navigation/navigation-menu';
import { inject } from '@angular/core';
import { environment } from 'environments/environment';
import { SubmitButtonService } from '../service/submit-button.service';

const URL = environment.url;
const RESQUESTS_NO_INTERCEPT = [
    {
        method: 'POST',
        url: `${URL}/integrations`,
    },
    {
        method: 'POST',
        url: `${URL}/dialogue-agents`,
    },
    {
        method: 'PATCH',
        url: `${URL}/channel-groups/:id`,
    },
    {
        method: 'POST',
        url: `${URL}/channel-groups`,
    },
    {
        method: 'GET',
        url: `${URL}/greeting-configurations/:id`,
    },
    {
        method: 'POST',
        url: `${URL}/firefish-integrations`,
    },
    {
        method: 'PATCH',
        url: `${URL}/firefish-integrations/:id`,
    },
    {
        method: 'POST',
        url: `${URL}/campaigns`,
    },
    {
        method: 'POST',
        url: `${URL}/messaging-groups`,
    },
];
/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    const toastrService = inject(ToastrService);
    const organisationService = inject(OrganisationService);
    const submitButtonService = inject(SubmitButtonService);
    const router = inject(Router);

    // Clone the request object
    const newReq = req.clone();

    // Response
    return next(newReq).pipe(
        catchError(error => {
            const noInterceptReq = RESQUESTS_NO_INTERCEPT.some(item => {
                const re = new RegExp(`^${item.url.replace(':id', '[a-zA-Z0-9-]')}*$`);
                return item.method === req.method && re.test(req.url);
            });
            if (noInterceptReq) {
                return throwError(() => error);
            }
            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse && error.status === 401) {
                authService.signOut();
            } else if (error instanceof HttpErrorResponse && error.status === 403) {
                if (organisationService.getOrganisationName()) {
                    toastrService.error(`You don't longer have access to the organisation ${organisationService.getOrganisationName()}`);
                }
                organisationService.removeActiveOrganisation();
                router.navigate([MenuRoute.ORGANISATIONS]);
            } else {
                if (req.method === 'PATCH' || req.method === 'POST') {
                    submitButtonService.setErrorRequest(true);
                }
                toastrService.error('Something went wrong...');
            }
            return throwError(() => error);
        })
    );
};
