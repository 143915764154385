import { FuseConfirmationConfig } from '@fuse/services/confirmation';

export const DIALOG_CONFIG: FuseConfirmationConfig = {
    title: 'Form modification',
    message: 'There are unsaved changes. Are you sure you want to discard it?',
    icon: {
        show: false,
    },
    actions: {
        confirm: {
            show: true,
            label: 'Yes. Discard changes',
            color: 'accent',
        },
        cancel: {
            show: true,
            label: 'Cancel',
        },
    },
    dismissible: true,
};

export const DIALOG_POPUP_CONFIG: FuseConfirmationConfig = {
    title: 'Sign-In Required',
    message: 'The Sign-in page will open in a seperate popup. Please click on on the Sign In button below to begin.',
    icon: {
        show: false,
    },
    actions: {
        confirm: {
            show: true,
            label: 'Sign In',
            color: 'accent',
        },
        cancel: {
            show: false,
            label: 'Cancel',
        },
    },
    dismissible: false,
    panelClass: ['open-popup-dialog'],
};

export const DIALOG_POPUP_OPEN_CONFIG: FuseConfirmationConfig = {
    title: 'Sign-In Required',
    message: 'The Sign-in page  has opened in a seperate popup window please sign in there to procede.',
    icon: {
        show: false,
    },
    actions: {
        confirm: {
            show: false,
        },
        cancel: {
            show: false,
        },
    },
    dismissible: false,
};
